<template>
  <!-- 保理融资 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <!-- <el-form-item label="融资申请时间" label-width="180px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item>
      <el-form-item label="融资到期日" label-width="180px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item> -->
    </FormSearch>
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
    <agreementListDialog ref="agreementListDialog" :finance-id="financeId" />
  </div>
</template>

<script>

import { getReverseFactoringList, signLoan, checkExamineFinanceButton } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import AgreementListDialog from './agreementListDialog.vue'
export default {
  components: { FormSearch, Pagination, Table, AgreementListDialog },
  data() {
    return {
      financeId: '',
      formInline: {
        financeNo: '',
        appStat: '', // 融资申请状态
        groupCmpName: '', // 集团
        proId: '', // 产品名称
        fundCmpId: '', // 资金方名称
        cmpName: '', // 供应商名称
        coreCmpId: '', // 核心企业名称
        loanType: '',
        invoiceType: '',
        financeApply: '',
        financeApplyBegin: '', // 融資申請時間起
        financeApplyEnd: '', // 融資申請時間止
        financeMaturity: '',
        financeMaturityBegin: '', // 融资到期日起
        financeMaturityEnd: '' // 融资到期日止
      },
      formItemArr: [
        { type: 'input', label: '融资申请编号', value: 'financeNo', width: '120px' },
        { type: 'select', label: '核心企业', value: 'coreCmpId', pLabel: 'cmpName', pValue: 'cmpCode', width: '80px', child: [] },
        { type: 'select', label: '资金方', pLabel: 'funderName', pValue: 'id', width: '80px', child: [] },
        { type: 'input', label: '融资企业名称', value: 'cmpName', width: '120px' },
        { type: 'input', label: '集团名称', value: 'groupCmpName', width: '80px' },
        { type: 'input', label: '产品名称', value: 'proId', width: '80px' },
        { type: 'select', label: '融资状态', value: 'appStat', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('LOAN_STATE'), width: '80px' },
        { type: 'select', label: '放款状态', value: 'loanType', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('LOAN_TYPE'), width: '80px' },
        { type: 'select', label: '开票状态', value: 'invoiceType', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('INVOICE_TYPE'), width: '80px' },
        { type: 'picker', label: '融资申请时间', value: 'date1', width: '80px' },
        { type: 'picker', label: '融资到期日', value: 'date2', width: '80px' }

      ],
      itemData: [
        { label: '融资申请编号', prop: 'financeNo', width: 120 },
        { label: '产品名称', prop: 'proName', width: 120 },
        { label: '资金方', prop: 'fundCmpName', width: 280 },
        { label: '融资企业名称', prop: 'cmpName', width: 180 },
        { label: '核心企业名称', prop: 'coreCmpName', width: 180 },
        { label: '集团名称', prop: 'groupCmpName', width: 180 },
        { label: '融资金额(元)', prop: 'appAmt', width: 180 },
        { label: '融资申请日', prop: 'createTime', width: 180 },
        { label: '融资到期日', prop: 'appDate', width: 180 }
        // { label: '融资状态', prop: 'statuss', child: [{ dictName: '进行中', dictId: 0 }, { dictName: '已下架', dictId: 1 }], width: 180 },
        // { label: '放款状态', prop: 'statusf', child: [{ dictName: '进行中', dictId: 0 }, { dictName: '已下架', dictId: 1 }], width: 180 },
        // { label: '开票状态', prop: 'statusg', child: [{ dictName: '进行中', dictId: 0 }, { dictName: '已下架', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '标记放款', handleEvent: this.handleLoan },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '协议签署', handleEvent: this.viewDetails },
        { bType: 'primary', label: '审核', handleEvent: this.handleAudit },
        { bType: 'primary', label: '还款明细', handleEvent: this.viewDetails },
        { bType: 'primary', label: '查看协议', handleEvent: this.handleLookAgreement },
        { bType: 'primary', label: '再次推送', handleEvent: this.viewDetails },
        { bType: 'primary', label: '通知供应商', handleEvent: this.viewDetails },
        { bType: 'primary', label: '生成凭证', handleEvent: this.viewDetails }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    bankList() {
      return this.$store.state.tradeFinancing.bankList
    }

  },
  async created() {
    if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
      await this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
    }
    if (!this.$store.state.tradeFinancing.bankList.length) {
      await this.$store.dispatch('tradeFinancing/querBankList')
      this.formItemArr.map(item => {
        if (item.label === '核心企业') {
          item.child = this.coreEnterPriseList
        }
        if (item.label === '资金方') {
          item.child = this.bankList
        }
      })
    }
  },
  methods: {
    getDate(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          state: '',
          goodsCategoryArr: []
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      getReverseFactoringList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.pageData
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    // 标识已放款 00-，01未放款，02已放款 开票状态 00-，01未开票，02开票中，03已开票
    // 标记放款
    handleLoan(row) {
      const h = this.$createElement
      this.$msgbox({
        title: '请确认',
        message: h('div', null, [
          h('p', null, '融资企业是否已收到银行放款？'),
          h('p', null, '收到请点击【确认已放款】，未收到请点击【取消】'),
          h('p', { style: 'color: #FF6600;margin-top:10px;' }, '注意：确认后该笔数据将统计至运营数据中！')
        ]),
        showCancelButton: true,
        confirmButtonText: '确认已放款',
        cancelButtonText: '取消'
      }).then(() => {
        this.signLoanFnAjax(row.financeId)
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    signLoanFnAjax(id) {
      signLoan(id, res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '标记放款成功，已将该笔融资数据统计至运营数据中！'
          })
          this.getDate()
        }
      })
    },
    checkFinanceButtonAjax(callback = null) {
      checkExamineFinanceButton(res => {
        if (res.code === 200) {
          this.financeButtonShow = res.data
          if (callback) callback(res.data)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 查看协议
    handleLookAgreement(row) {
      this.financeId = row.financeId
      this.openDialog('agreementListDialog')
    },
    openDialog(dialogName) {
      this.$refs[dialogName].dialogVisible = true
    },
    viewDetails(row) {
      this.$router.push({
        path: '/financeManage/reverseFactoring/detail',
        name: 'reverseFactoringDetail',
        query: {
          financeId: row.financeId,
          financeNo: row.financeNo,
          currentNodeId: row.currentNodeId,
          activiteId: row.activiteId,
          type: '1',
          appStat: row.appStat,
          cmpId: row.cmpId,
          appTcapi: row.appTcapi,
          credId: row.credId
        }
      })
    },
    // 审核
    handleAudit(row) {
      this.$router.push({
        path: '/financeManage/reverseFactoring/audit',
        name: 'reverseFactoringAudit',
        query: {
          financeId: row.financeId,
          financeNo: row.financeNo,
          currentNodeId: row.currentNodeId,
          activiteId: row.activiteId,
          type: '2',
          appStat: row.appStat,
          cmpId: row.cmpId,
          appTcapi: row.appTcapi,
          credId: row.credId
        }
      })
    }
  }
}
</script>

<style>

</style>
